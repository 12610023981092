import React, { FunctionComponent, LazyExoticComponent } from "react";
import { RouteProps } from "react-router";
import { User } from "../models";
import { Roles } from "../utils";

export const withRole = (
	component: JSX.Element | LazyExoticComponent<React.ComponentType> | LazyExoticComponent<FunctionComponent<{}>>,
	allowedRoles: { [key: string]: boolean },
	user: User
): RouteProps['component'] => {
	//@ts-ignore
	if (allowedRoles[user.role]) return component;
	//@ts-ignore
	return null;
};

export type AllowedRoles = { [key in Roles]: boolean };