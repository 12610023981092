import './CollapsableList.css';
import { ListItemText, ListItemIcon, ListItem, List } from '@mui/material';
import React, { useState } from 'react';
import { Icon, IconProps, ExpandButton, Collapse } from '..';

interface ListItemProps {
	onClick?: () => void;
	primary: string;
	secondary?: string;
}

export interface CollapsableListProps {
	classNames?: {
		anchor: string;
		items: string;
	};
	isOpen?: boolean;
	list: ListItemProps[];
	title?: string;
	titleIcon?: IconProps["iconName"];
}

export const CollapsableList: React.FunctionComponent<CollapsableListProps> = ({
	title,
	list,
	titleIcon,
	isOpen,
	classNames
}) => {
	const [open, setOpen] = useState<boolean>(isOpen || false);
	const handleClick = () => setOpen(!open);
	if (!list.length) return null;
	const anchorClassName = classNames && classNames.anchor ? classNames.anchor : 'list-item';
	const itemClasses = classNames && classNames.items ? classNames.items : 'item';

	return (
		<List>
			<ListItem className={anchorClassName} onClick={handleClick}>
				{titleIcon && (
					<ListItemIcon>
						<Icon iconName={titleIcon} />
					</ListItemIcon>
				)}
				<ListItemText primary={title} />
				<ExpandButton handleClick={handleClick} isOpen={open} disabled={false} isIcon />
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				{
					list.map((o, i) => {
						return (
							<div onClick={o.onClick || undefined} key={i} className={itemClasses}>
								<span>{o.primary}</span>
								<span>{o.secondary}</span>
							</div>
						);
					})
				}
			</Collapse>
		</List>
	);
};
