import moment from "moment";
import { DEFAULT_NEW_QUERY_ID } from "../constants";
import { SuportedObject } from "../utils/filterListByFilters";
import { defaultUser, UserDictionary } from "./user";

export interface Photo extends SuportedObject {
	id: string;
	contentType: string;
	createdBy: string;
	createdByEmail: string;
	description: string;
	fileName: string;
	location: string;
	url: string;
	version: number;
};

export interface PhotoDictionary {
	[key: Photo['id']]: Photo
};

export const defaultPhoto: Photo = {
	id: '',
	contentType: '',
	createdBy: '',
	createdByEmail: '',
	description: '',
	fileName: '',
	location: '',
	url: '',
	version: 0,
};

export const mapPhotoForApi = (queryId: string, photo: Photo): any => {
	let payload: { [key: string]: any } = {
		contentType: photo.contentType,
		createdBy: photo.createdBy,
		createdByEmail: photo.createdByEmail,
		description: photo.description,
		fileName: photo.fileName,
		location: photo.location,
		url: photo.url,
		version: photo.version,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = photo.id
	}
	return payload;
};

export const mapPhotoFromAPI = (
	source: any,
	userDictionary: UserDictionary
): Photo => {
	const foundUser = userDictionary[source.id] || { ...defaultUser };
	return {
		id: source.id,
		contentType: source.contentType,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		description: source.description,
		fileName: source.fileName,
		location: source.location,
		url: source.url,
		version: source.version,
	};
};

export const mapPhotosFromAPI = (
	objectsFromAPI: any[],
	userDictionary: UserDictionary
): Photo[] => {
	return objectsFromAPI.map((objectFromAPI) => mapPhotoFromAPI(objectFromAPI, userDictionary));
};

export const orderPhotos = (objects: Photo[], isAscending: boolean): Photo[] => {
	return objects.sort((a, b) => {
		const isAfter = moment(a.modifiedDate, 'YYYY-MM-DD').isAfter(moment(b.modifiedDate, 'YYYY-MM-DD'));
		if (isAscending) {
			return isAfter ? 1 : -1;
		} else {
			return isAfter ? -1 : 1;
		}
	});
};