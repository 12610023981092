import './BooksApp.css';
import React, { Suspense, lazy, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
	Preloader,
	SuccessModal,
	ErrorModal,
} from '../../components';
import { withRole } from '../../HOC';
import { buildRolesDictionary, Roles, turnListIntoDictionary } from '../../utils';
import { useUsers, useAPIList, useLabels } from '../../hooks';
import { UserContext } from '../../context/UserContext';
import { UsersContext, TagsContext, LabelsContext, LocaleContext } from '../../context';
import { TYPES_ENUM } from '../../API/TYPES';
import { Tag, mapTagsFromAPI } from '../../models';
import { BookAppDrawer } from './components';

const Authors = lazy(() => import('./pages/Authors/Authors'));
const AuthorPage = lazy(() => import('./pages/Authors/AuthorPage'));
const Books = lazy(() => import('./pages/Books/Books'));
const BookPage = lazy(() => import('./pages/Books/BookPage'));
const NotFound = lazy(() => import('../../pages/Common/NotFound/NotFound'));
const OwnedBooks = lazy(() => import('./pages/OwnedBooks/OwnedBooks'));
const OwnedBookPage = lazy(() => import('./pages/OwnedBooks/OwnedBookPage'));
const BookReadings = lazy(() => import('./pages/BookReadings/BookReadings'));
const BookReadingPage = lazy(() => import('./pages/BookReadings/BookReadingPage'));
const WishedBooks = lazy(() => import('./pages/WishedBooks/WishedBooks'));
const WishedBookPage = lazy(() => import('./pages/WishedBooks/WishedBookPage'));

export const BooksApp = () => {
	const { user } = React.useContext(UserContext);
	const { locale } = useContext(LocaleContext);
	const [users, usersDictionary, isLoadingUsers, loadUsers] = useUsers();
	const [
		isLoadingTags,
		isDeletingTag,
		tagsRaw, ,
		loadTags,
		selectTags,
		selectAllTags,
		deleteTag,
		overwriteSelected,
		selectedTags
	] = useAPIList<Tag>(TYPES_ENUM.TAG);
	const tags = mapTagsFromAPI(tagsRaw);
	const tagDictionary = turnListIntoDictionary(tags);

	const adminOnly = buildRolesDictionary([Roles.admin]);
	const allRoles = buildRolesDictionary([Roles.admin, Roles.superUser, Roles.visitor]);
	const { isLoadingLabels, labels } = useLabels(locale);

	if (isLoadingLabels || isLoadingUsers || isLoadingTags) return <Preloader />;
	return (
		<LabelsContext.Provider value={{ isLoadingLabels: isLoadingLabels, labels: labels }}>
			{/* @ts-ignore */}
			<UsersContext.Provider value={{ isLoadingUsers, users, loadUsers, usersDictionary }}>
				<TagsContext.Provider value={{
					deleteTag,
					isDeletingTag,
					isLoadingTags,
					tags,
					tagDictionary,
					loadTags,
					selectAllTags,
					selectedTags,
					selectTags,
					overwriteSelected,
				}}>
					<div className='admin-app'>
						<div className='drawer-wrapper'>
							<BookAppDrawer />
						</div>
						<main className='page-wrapper'>
							<Suspense fallback={<Preloader isDbz />}>
								<Switch>
									<Route exact path="/" component={withRole(BookReadings, allRoles, user)} />
									<Route exact path="/authors" component={withRole(Authors, adminOnly, user)} />
									<Route exact path="/authors/:id" component={withRole(AuthorPage, adminOnly, user)} />
									<Route exact path="/books" component={withRole(Books, adminOnly, user)} />
									<Route exact path="/books/:id" component={withRole(BookPage, adminOnly, user)} />
									<Route exact path="/ownedBooks" component={withRole(OwnedBooks, allRoles, user)} />
									<Route exact path="/ownedBooks/:id" component={withRole(OwnedBookPage, allRoles, user)} />
									<Route exact path="/bookReadings" component={withRole(BookReadings, allRoles, user)} />
									<Route exact path="/bookReadings/:id" component={withRole(BookReadingPage, allRoles, user)} />
									<Route exact path="/wishedBooks" component={withRole(WishedBooks, allRoles, user)} />
									<Route exact path="/wishedBooks/:id" component={withRole(WishedBookPage, allRoles, user)} />
									<Route component={NotFound} />
								</Switch>
							</Suspense>
						</main>
						<ErrorModal />
						<SuccessModal handleClose={() => { }} isOpen={false} message="" />
					</div>
				</TagsContext.Provider>
			</UsersContext.Provider>
		</LabelsContext.Provider>
	);
}

export default BooksApp;