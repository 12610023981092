import React from 'react';
import { Typography, TypographyProps } from '../Typography';

interface PageTitleProps {
	variant?: TypographyProps["variant"];
	align?: TypographyProps["align"];
	text: string;
}

export const PageTitle: React.FunctionComponent<PageTitleProps> = ({ variant, align, text }) => {
	return (
		<Typography variant={variant || 'h4'} align={align || 'center'} color="textPrimary">
			{text}
		</Typography>
	);
};

export default PageTitle;