import React from 'react';
import { SupportedObject } from '../components';
import { getInputChangeParams } from './getInputChangeParams';

export const buildHandleInputChange = <T extends SupportedObject>(
	originalObject: T,
	setObject: (object: T) => void,
) => {
	const handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLInputElement> = (e) => {
		let [field, value] = getInputChangeParams<string, string>(e);
		const updated: { [key: string]: any } = { ...originalObject };
		updated[field] = value;
		setObject(updated as T);
	};
	return handleInputChange;
};