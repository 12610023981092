import React, { useContext } from 'react';
import { Button, Icon, ICON_NAMES } from '..';
import { LabelsContext } from '../../context';

interface SaveButtonProps {
	onClick: () => Promise<void>;
	disabled: boolean;
}

export const SaveButton: React.FunctionComponent<SaveButtonProps> = ({ onClick, disabled }) => {
	const { labels } = useContext(LabelsContext);
	return (
		<Button
			variant="contained"
			color="primary"
			size="large"
			onClick={onClick}
			disabled={disabled}
		>
			<Icon iconName={ICON_NAMES.save} />
			{labels.save}
		</Button>
	);
}

export default SaveButton;