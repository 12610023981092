import { DEFAULT_NEW_QUERY_ID } from "../constants";
import { SuportedObject } from "../utils/filterListByFilters";
import { UserDictionary, defaultUser } from "./user";

export interface Skill extends SuportedObject {
	id: string;
	type: string;
	profile: string;
	title: string;
	description: string;
	locale: string;
	modifiedDate: string;
	createdBy: string;
	createdByEmail: string;
	owner: string;
	ownerEmail: string;
};

export interface SkillDictionary {
	[key: Skill['id']]: Skill
};

export const defaultSkill = {
	id: '',
	type: '',
	profile: '',
	title: '',
	description: '',
	locale: '',
	modifiedDate: '',
	createdBy: '',
	createdByEmail: '',
	owner: '',
	ownerEmail: '',
};

export const mapSkillForApi = (queryId: string, skill: Skill): any => {
	let payload: { [key: string]: any } = {
		type: skill.type,
		profile: skill.profile,
		title: skill.title,
		description: skill.description,
		locale: skill.locale,
		modifiedDate: skill.modifiedDate,
		createdBy: skill.createdBy,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = skill.id
	}
	return payload;
};

export const mapSkillFromAPI = (source: any, userDictionary: UserDictionary) => {
	const foundUser = userDictionary[source.createdBy] || { ...defaultUser }
	return {
		id: source.id,
		type: source.type,
		profile: source.profile,
		title: source.title,
		description: source.description,
		locale: source.locale,
		modifiedDate: source.modifiedDate,
		createdBy: source.createdBy,
		createdByEmail: foundUser.email,
		owner: source.email,
		ownerEmail: foundUser.email,
	} as Skill;
};

export const mapSkillsFromAPI = (skillsFromAPI: any[], userDictionary: UserDictionary): Skill[] => {
	return skillsFromAPI.map((skillFromAPI) => mapSkillFromAPI(skillFromAPI, userDictionary));
};