import React from 'react';
import { CustomTextfield, CustomTextfieldProps } from '../CustomTextfield';

type CustomEmailFieldProps = Omit<CustomTextfieldProps, 'type'>;

export const CustomEmailField: React.FunctionComponent<CustomEmailFieldProps> = (props) => {
	return <CustomTextfield type="email" {...props} />;
}

export default CustomEmailField;
