import React from 'react';
import { CustomTextfield, CustomTextfieldProps } from '../CustomTextfield';

interface CustomPasswordFieldProps extends CustomTextfieldProps {
	type?: 'password'
};

export const CustomPasswordField: React.FunctionComponent<CustomPasswordFieldProps> = (props) => {
	return <CustomTextfield type="password" {...props} />;
}

export default CustomPasswordField;
