import './order-button.css';
import React from 'react';
import { Fab } from '@mui/material';
import { NavigationSharp } from '@mui/icons-material';

interface OrderButtonProps {
	isAscending: boolean;
	onClick: () => void;
	text: string;
}

export const OrderButton: React.FunctionComponent<OrderButtonProps> = ({ isAscending, onClick, text }) => {
	return (
		<Fab variant="extended" className={`margin--half-rem`} onClick={onClick}>
			<NavigationSharp className={`margin-right--half-rem ${isAscending ? 'icon-ascending' : 'icon-descending'}`} />
			{text}
		</Fab>
	);
};
