import './SectionHeader.css'
import React from 'react';
import { getPreferredColorScheme, THEMES } from '../../utils';

interface SectionHeaderProps {
	title: string;
	details?: string;
	hasThemeSupport?: boolean;
	handleOnClick?: () => void;
}

export const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
	title,
	details,
	hasThemeSupport,
	handleOnClick,
}) => {
	if (hasThemeSupport) {
		const detectedTheme = getPreferredColorScheme();
		const titleClasses = `section-header-title ${detectedTheme === THEMES.light ? 'section-header-title--light' : 'section-header-title--dark'}`;
		const detailsClasses = `section-header-details ${detectedTheme === THEMES.light ? 'section-header-details--light' : 'section-header-details--dark'}`;
		return (
			<header className={handleOnClick ? 'cursor--pointer' : ''} onClick={handleOnClick}>
				<h3 className={titleClasses}>{title}</h3>
				{details && <p className={detailsClasses}>{details}</p>}
			</header>
		);
	}
	return (
		<header className={handleOnClick ? 'cursor--pointer' : ''} onClick={handleOnClick}>
			<h3 className="section-header-title section-header-title--light">{title}</h3>
			{details && <p className="section-header-details section-header-details--light">{details}</p>}
		</header>
	);
};

export default SectionHeader;