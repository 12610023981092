import randomcolor from 'randomcolor';
export const RANDOM_COLORS = [
	randomcolor({
		hue: 'blue',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'red',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'purple',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'yellow',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'green',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'orange',
		luminosity: 'bright',
		format: 'hex'
	}),
	randomcolor({
		hue: 'blue',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'red',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'purple',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'yellow',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'green',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'orange',
		luminosity: 'light',
		format: 'hex'
	}),
	randomcolor({
		hue: 'blue',
		luminosity: 'dark',
		format: 'hex'
	}),
	randomcolor({
		hue: 'red',
		luminosity: 'dark',
		format: 'hex'
	}),
	randomcolor({
		hue: 'purple',
		luminosity: 'dark',
		format: 'hex'
	}),
	randomcolor({
		hue: 'yellow',
		luminosity: 'dark',
		format: 'hex'
	}),
	randomcolor({
		hue: 'green',
		luminosity: 'dark',
		format: 'hex'
	}),
	randomcolor({
		hue: 'orange',
		luminosity: 'dark',
		format: 'hex'
	})
];