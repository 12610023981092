import React, { useMemo } from 'react';
import CustomSelector, { CustomSelectorProps, Option } from '../../../../components/CustomSelector/CustomSelector';
import { useContext } from 'react';
import { LabelsContext } from '../../../../context';
import { Book } from '../../../../models';
import { Icon, ICON_NAMES, MenuItem } from '../../../../components';
import { OwnedBook } from '../../models';

interface BookOption extends Option {
	isOwned: boolean;
}

export interface BookSelectorProps {
	disabled: boolean;
	handleChange: ((id: Book['id']) => void);
	value: string;
	books: Book[];
	ownedBooks?: OwnedBook[];
}

export const BookSelector: React.FunctionComponent<BookSelectorProps> = ({ disabled, value, handleChange, books, ownedBooks }) => {
	const { labels } = useContext(LabelsContext);
	const bookSelectorOptions = useMemo(() => {
		return mapBookSelector(books, ownedBooks || []);
	}, [books, ownedBooks]);

	const handleCangeLocal: CustomSelectorProps['handleChange'] = (field, value) => {
		handleChange(value);
	};

	return (
		<CustomSelector
			id="BookSelector"
			name="bookId"
			label={labels.bookName}
			value={value}
			options={bookSelectorOptions}
			onChange={() => { }}
			handleChange={handleCangeLocal}
			disabled={disabled}
			//@ts-ignore
			optionRenderer={(option: BookOption) => {
				return (
					<MenuItem key={option.value} style={{ display: 'flex', justifyContent: 'space-between' }} value={option.value}>
						{option.label} {option.isOwned ? <Icon iconName={ICON_NAMES.Cash} /> : null}
					</MenuItem>
				);
			}}
		/>
	);
}

export default BookSelector;

const mapBookSelector = (books: Book[], ownedBooks: OwnedBook[]): BookOption[] => {
	const foundOwnedBooks: { [key: string]: Book } = {};
	const result: BookOption[] = [];
	for (let i = 0; i < ownedBooks.length; i++) {
		const ownedBook = ownedBooks[i];
		if (!foundOwnedBooks[ownedBook.id]) {
			foundOwnedBooks[ownedBook.book.id] = ownedBook.book;
			result.push({ value: ownedBook.book.id, label: ownedBook.book.title, isOwned: true });
		}
	}
	return result.concat(books.filter(book => !foundOwnedBooks[book.id]).map(book => ({ value: book.id, label: book.title, isOwned: false })));
};