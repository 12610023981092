import { Option } from "../../../context";
import { LabelsGraph } from "../../../hooks";

export enum AssetType {
	ACCOUNT = 'ACCOUNT',
	CASH = 'CASH',
	DEPOSIT = 'DEPOSIT',
	SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
	INVESTMENT_FUNDS = 'INVESTMENT_FUNDS',
	REAL_ESTATE = 'REAL_ESTATE',
	CAR = 'CAR',
	HOME_ELECTRONICS = 'HOME_ELECTRONICS',
	TREASURY_BONDS = 'TREASURY_BONDS',
}

export type AssetTypeLabels = { [key in AssetType]: string };

export const getAssetTypeLabels = (labels: LabelsGraph): AssetTypeLabels => {
	return {
		ACCOUNT: labels.currentAccount,
		CAR: labels.carLabel,
		CASH: labels.cash,
		DEPOSIT: labels.deposit,
		HOME_ELECTRONICS: labels.homeElectronics,
		INVESTMENT_FUNDS: labels.investmentFunds,
		REAL_ESTATE: labels.realEstate,
		SAVINGS_ACCOUNT: labels.savingsAccount,
		TREASURY_BONDS: labels.treasuryBonds,
	};
};

export const getAssetTypeSelectorOptions = (labels: LabelsGraph): Option[] => {
	const assetTypeLabels = getAssetTypeLabels(labels);
	return Object.values(AssetType).map(assetType => ({ label: assetTypeLabels[assetType], value: assetType }));
};