import React from 'react';
import { Button } from '../Button';
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '../';

interface SimpleYesNoDialogProps {
	open: boolean;
	handleClose: () => void;
	handleConfirm: () => Promise<void>;
	title: string;
	text: string;
	confirmButton: string;
	cancelButton: string;
}

export const SimpleYesNoDialog: React.FunctionComponent<SimpleYesNoDialogProps> = (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="Simple-yes-no-dialog-title"
		>
			<DialogTitle style={{ cursor: 'move' }} id="Simple-yes-no-dialog-title">
				{props.title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleConfirm} color="primary">
					{props.confirmButton}
				</Button>
				<Button onClick={props.handleClose} color="secondary">
					{props.cancelButton}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SimpleYesNoDialog;