import React, { Fragment, lazy } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { withSuspense } from '../../HOC';

const Grid = lazy(() => import('../../components/Grid/Grid'));

export enum AcceptedType {
	images = 'image/*'
}

const AcceptedTypeValues = Object.values(AcceptedType);

export interface File {
	name: string;
	type: string;
	lastModifiedDate: Date;
}

interface CustomFileUploaderProps {
	files: {
		selected: File[];
		currentFolder: string;
	};
	acceptType: AcceptedType;
	onFileFieldChange: (e: React.ChangeEvent) => void;
	onFileUpload: () => Promise<void>;
}

export const CustomFileUploader: React.FunctionComponent<CustomFileUploaderProps> = (props) => {
	// TODO: Add labels
	const { files, acceptType, onFileFieldChange, onFileUpload } = props;

	const renderFileDetails = (file: File, index: number) => {
		return (
			<Fragment key={`selectedFile${index}`}>
				<p>File Name: {file.name}</p>
				<p>File Type: {file.type}</p>
				<p>
					Last Modified:{" "} {file.lastModifiedDate.toDateString()}
				</p>
			</Fragment>
		);
	}

	const renderFileDetailsList = () => {
		return (
			<div>
				<h2>File Details:</h2>
				{Object.values(files.selected).map((file, index) => renderFileDetails(file, index))}
			</div>
		);
	}

	const renderEmptyFilesList = () => {
		return (
			<div>
				<br />
				<h4>Choose before Pressing the Upload button</h4>
			</div>
		);
	}

	const renderFileData = () => {
		if (files.selected.length) {
			return renderFileDetailsList()
		}
		return renderEmptyFilesList()
	}

	const renderButtons = () => {
		return (
			<Fragment>
				<Button onClick={onFileUpload}>
					Upload
				</Button>
			</Fragment>
		)
	}

	return withSuspense(
		<Grid container spacing={3}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<p>{files.currentFolder}</p>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<input
					type="file"
					accept={acceptType}
					onChange={onFileFieldChange}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				{renderFileData()}
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				{renderButtons()}
			</Grid>
		</Grid>
	);
}

CustomFileUploader.propTypes = {
	files: PropTypes.shape({
		selected: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
		}).isRequired).isRequired,
		currentFolder: PropTypes.string.isRequired,
	}).isRequired,
	acceptType: PropTypes.oneOf(AcceptedTypeValues).isRequired,
	onFileFieldChange: PropTypes.func.isRequired,
	onFileUpload: PropTypes.func.isRequired
}

export default CustomFileUploader;