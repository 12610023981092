import { useCallback, useContext, useState } from "react";
import { UserContext } from "../context";

type ResponseType = [
	string,
	(error: Error) => void,
];

export const useErrors = (): ResponseType => {
	const [error, setError] = useState('');
	const { logout } = useContext(UserContext);

	const updateError = useCallback((err) => {
		if (err) console.error(err);
		let message = '';
		if (err && err.response && err.response.data && err.response.data.status === 'jwt-expired') {
			message = err.response.data.message;
			logout();
		} else {
			message = err.message;
		}
		setError(message);
	}, [logout]);

	return [error, updateError];
};