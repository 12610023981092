import { SuportedObject } from "../../../utils/filterListByFilters";

interface Activity {
	id: string;
	details: string;
}

interface DoneActivity extends Activity {
	date: Date;
}

export interface SuggestedActivity extends SuportedObject {
	id: string;
	suggestion: Activity;
	previous: DoneActivity[];
};


export interface SuggestedActivityDictionary {
	[key: SuggestedActivity['id']]: SuggestedActivity
};

export const defaultSuggestedActivity: SuggestedActivity = {
	id: '',
	suggestion: {
		details: '',
		id: '',
	},
	previous: [],
};

export const mapSuggestedActivityFromAPI = (source: any): SuggestedActivity => {
	return {
		id: '',
		suggestion: source.suggestion,
		previous: (source.previous || []).map((prev: any) => {
			return {
				id: prev.id,
				details: prev.details,
				date: new Date(prev.date),
			}
		})
	} as SuggestedActivity;
};