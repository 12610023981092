import { PropsWithChildren } from 'react';
import { UseChipsResult } from './useFilterChipsState';
import { Chip } from '../Chip';

interface TagLike { id: string, text: string };

export interface FilterChipsProps<T extends TagLike> {
	useChipsHook: UseChipsResult<T>;
	availableChips: T[];
	onChipsClickHadler?: (newChips: T[]) => void;
};

export const FilterChips = <T extends TagLike>(
	props: PropsWithChildren<FilterChipsProps<T>>
) => {
	const { availableChips, useChipsHook, onChipsClickHadler } = props;
	const { selectedChips, setSelectedChips } = useChipsHook;

	const handleTagClick = (tag: T) => () => {
		if (selectedChips.has(tag.id)) {
			selectedChips.delete(tag.id);
		} else {
			selectedChips.set(tag.id, tag);
		}
		setSelectedChips(new Map(selectedChips));
		if (onChipsClickHadler) {
			onChipsClickHadler(Array.from(selectedChips.values()))
		}
	};

	return (
		<>
			{
				availableChips.map((usedTag) => {
					const isInClickedTags = selectedChips.has(usedTag.id);
					return (
						<Chip key={usedTag.id}
							style={isInClickedTags ? { backgroundColor: 'red' } : {}}
							label={usedTag.text}
							onClick={handleTagClick(usedTag)}
						/>
					);
				})
			}
		</>
	);
};

export default FilterChips;