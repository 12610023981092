import React from 'react'
import PropTypes from 'prop-types'
import { INPUT_VARIANTS, INPUT_MARGINS, ValidationCallback } from '../CustomTextfield/';
import { IFormValidator } from '../../validators';
import { TextField } from '@mui/material';

interface TextareaProps {
	autoFocus?: boolean;
	className?: string;
	disabled?: boolean;
	error?: boolean;
	fullWidth?: boolean;
	id: string;
	label?: string;
	margin?: 'dense' | 'normal' | 'none';
	name: string;
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLInputElement>;
	required?: boolean;
	placeholder?: string;
	rows?: number;
	validationCallback?: ValidationCallback;
	value: string;
	variant?: 'standard' | 'filled' | 'outlined';
	tooltip?: string;
	formValidator?: IFormValidator;
}

export const Textarea: React.FunctionComponent<TextareaProps> = (props) => {
	// TODO: Use Base interface for Props
	const { label, id, name, rows, value, onChange, className, margin, variant, autoFocus, disabled, required, fullWidth, placeholder } = props;
	const evaluatedError = evaluateError(props);
	return (
		<TextField
			autoFocus={autoFocus}
			id={id}
			name={name}
			label={label}
			multiline
			minRows={rows + ''}
			value={value}
			onChange={onChange}
			className={className}
			margin={margin}
			variant={variant}
			error={evaluatedError}
			disabled={disabled}
			required={required}
			fullWidth={fullWidth}
			placeholder={placeholder}
		/>
	);
}

Textarea.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	rows: PropTypes.number,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	validationCallback: PropTypes.func,
	error: PropTypes.bool,
	margin: PropTypes.oneOf(Object.values(INPUT_MARGINS)),
	variant: PropTypes.oneOf(Object.values(INPUT_VARIANTS)),
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	fullWidth: PropTypes.bool,
	placeholder: PropTypes.string,
}

Textarea.defaultProps = {
	rows: 4,
	value: '',
	onChange: () => { },
	validationCallback: (name: string, value: string) => false,
	margin: INPUT_MARGINS.normal,
	variant: INPUT_VARIANTS.outlined,
	autoFocus: false,
	disabled: false,
	required: false,
	fullWidth: true,
	placeholder: '',
};

export default Textarea;

const evaluateError = (props: TextareaProps): boolean => {
	const { formValidator, name, value, error, validationCallback } = props;
	if (formValidator) {
		return formValidator.fieldHasErrors(name, { [name]: value });
	}

	return (error !== undefined) ? error : (validationCallback ? validationCallback(name, value) : false);
}