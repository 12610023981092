import React, { useMemo } from 'react';
import CustomSelector, { CustomSelectorProps } from '../../../../components/CustomSelector/CustomSelector';
import { useContext } from 'react';
import { LabelsContext } from '../../../../context';
import { Author } from '../../models';

export interface AuthorsSelectorProps {
	disabled: boolean;
	handleChange: ((authors: Author['id'][]) => void);
	value: string | string[];
	authors: Author[];
}

export const AuthorsSelector: React.FunctionComponent<AuthorsSelectorProps> = ({ disabled, value, handleChange, authors }) => {
	const { labels } = useContext(LabelsContext);
	const authorSelectorOptions = useMemo(() => {
		return authors.map((author) => ({ value: author.id, label: author.fullName }));
	}, [authors]);

	const handleCangeLocal: CustomSelectorProps['handleChange'] = (field, value) => {
		handleChange(value);
	};

	return (
		<CustomSelector
			id="UserSelector"
			name="authors"
			label={labels.authors}
			value={value}
			options={authorSelectorOptions}
			handleChange={handleCangeLocal}
			disabled={disabled}
			multiple
		/>
	);
}

export default AuthorsSelector;