import moment, { Moment } from 'moment';
import React, { useContext, useMemo } from 'react';
import CustomSelector from '../CustomSelector/CustomSelector';
import { LabelsContext } from '../../context';

interface MonthSelectorProps {
	id: string;
	className?: string;
	disabled: boolean;
	handleChange: (option: { label: string, value: string, startDate: Moment, endDate: Moment }) => void;
	numberOfMonths: number;
	startDate: string;
}

export const MonthSelector: React.FunctionComponent<MonthSelectorProps> = ({ className, disabled, handleChange, numberOfMonths, startDate, id }) => {
	const monthOptions = useMemo(() => getMonthOptions(numberOfMonths), [numberOfMonths]);
	const selectedMonth = useMemo(() => {
		const startDateAsOptionValue = moment(startDate).startOf('month').format('MM-YYYY');
		const found = monthOptions.find((option) => option.label === startDateAsOptionValue) || { label: startDateAsOptionValue, value: '0', };
		return found.value;
	}, [monthOptions, startDate]);
	const { labels } = useContext(LabelsContext);

	const onChange = (_field: string, value: string) => {
		const foundOption = monthOptions.find((option) => option.value === value) || { label: '', value: '', startDate: moment(), endDate: moment() };
		handleChange(foundOption);
	};

	return (
		<CustomSelector
			id={id}
			name="selectedMonth"
			className={className}
			label={labels.createdBy}
			value={selectedMonth}
			options={monthOptions}
			handleChange={onChange}
			disabled={disabled}
		/>
	);
}

export default MonthSelector;

const getMonthOptions = (numberOfMonths: number) => {
	const result = [];
	let currentMonth = moment();
	for (let i = 0; i < numberOfMonths; i++) {
		const start = moment(currentMonth.toISOString()).startOf('month');
		const end = moment(currentMonth.toISOString()).endOf('month');
		result.push({
			label: `${start.format('MM-YYYY')}`,
			value: i + '',
			startDate: start,
			endDate: end,
		});
		currentMonth.subtract(1, 'month');
	}
	return result;
}