import { UserSettings } from "./UserSettings";
import { USER_SETTINGS_KEY } from "./USER_SETTINGS_KEY";

export const getUserSettings = (): UserSettings => {
	const userSettingsRaw = JSON.parse(localStorage.getItem(USER_SETTINGS_KEY) || '{}');
	if (!userSettingsRaw.hasBookReadingsOnTimeline) {
		return { ...userSettingsRaw, ...{ hasBookReadingsOnTimeline: false } };
	}
	if (!userSettingsRaw.hasMovieScreeningsOnTimeline) {
		return { ...userSettingsRaw, ...{ hasMovieScreeningsOnTimeline: false } };
	}
	return userSettingsRaw;
};