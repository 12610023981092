import './SkillProgressBar.css'
import React from 'react';

interface SkillProgressBarProps {
	percentage: number;
	text?: string;
}

// TODO:
// Refactor without fucking bootstrap
export const SkillProgressBar: React.FunctionComponent<SkillProgressBarProps> = ({ percentage, text }) => {
	return (
		<div className="progress">
			<div role="progressbar"
				className={getProgreebarClasses(percentage)}
				style={getProgreebarStyles(percentage)}
				aria-valuenow={percentage}
				aria-valuemin={0}
				aria-valuemax={100}
			>
				<span className="skill">
					{text}
					<i className="val">
						{getPercentageText(percentage)}
					</i>
				</span>
			</div>
		</div>
	);
};

export default SkillProgressBar;

const getProgreebarClasses = (percentage: number) => {
	if (percentage <= 50) return 'progress-bar bg-danger';
	if (percentage <= 70) return 'progress-bar bg-warning';
	return 'progress-bar bg-success';
}

const getProgreebarStyles = (percentage: number) => ({ width: getPercentageText(percentage) });

const getPercentageText = (text: number) => text + '%';
