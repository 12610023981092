import { THEMES } from './THEMES';

/**
 * Returns the selected browser theme.
*/
export const getPreferredColorScheme = (): THEMES => {
	if (window.matchMedia) {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) return THEMES.dark;
	}
	return THEMES.light;
}