import React from 'react';
import { Button } from '../Button';
import { Icon, ICON_NAMES } from '..';

interface ModifyButtonProps {
	onClick: () => void;
	disabled?: boolean;
	labels: {
		update: string;
	};
}

export const ModifyButton: React.FunctionComponent<ModifyButtonProps> = ({ onClick, disabled, labels }) => {
	return (
		<Button
			variant="contained"
			color="primary"
			onClick={onClick}
			disabled={disabled}
		>
			<Icon iconName={ICON_NAMES.modify} />
			{labels.update}
		</Button>
	);
};

export default ModifyButton;