import { Suspense } from 'react';
import { Preloader } from '../components';

export enum PreloaderType {
	Classic = 'classic',
}

export const withSuspense = (Component: JSX.Element, preloaderType?: PreloaderType) => {
	const preloaderToUse = getPreloader(preloaderType);
	return (
		<Suspense fallback={preloaderToUse}>
			{Component}
		</Suspense>
	)
};

const getPreloader = (preloaderType?: PreloaderType) => {
	switch (preloaderType) {
		case PreloaderType.Classic:
			return <Preloader />;
		default:
			return null;
	}
}