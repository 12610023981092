import { useCallback, useState } from "react";
import { SupportedObject } from "../components";
import { filterListByFilters } from "../utils";
import { Filter } from "../utils/filterListByFilters";

export interface UseFiltersResult<T extends SupportedObject> {
	filters: Filter[];
	setFilters: (newFilters: Filter[]) => void;
	filterList: (list: T[]) => T[];
}

export const useFilters = <T extends SupportedObject>(intialFilters?: Filter[]): UseFiltersResult<T> => {
	const [filters, setFilters] = useState<Filter[]>(intialFilters || []);

	const filterList = useCallback((list: T[]) => {
		return filterListByFilters(list, filters);
	}, [filters]);

	return {
		filters,
		setFilters,
		filterList,
	};
};