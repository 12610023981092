import { REST_ADDRESS } from '../constants';
import { getResourceBaseUrl } from './getResourceBaseUrl';
import { TYPES_ENUM } from './TYPES';

/**
 * Returns the resource URL
*/
export const getResourceURL = (type: TYPES_ENUM, id: string = '', params: { [key: string]: string } = {}) => {
	const resourceBasicURL = getResourceBaseUrl(type);
	let baseURL = `${REST_ADDRESS}${resourceBasicURL}`;
	if (id) baseURL += `/${id}`;
	const url = new URL(baseURL);
	if (params) {
		for (const key in params) {
			url.searchParams.append(key, params[key]);
		}
	}
	return url.href;
};
