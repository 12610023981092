import { createContext } from 'react';
import { User, UserDictionary } from '../models/user';

interface UsersContextProps {
	isLoadingUsers: boolean,
	users: User[],
	usersDictionary: UserDictionary,
	loadUsers: (params: any) => void,
}

export const UsersContext = createContext<UsersContextProps>({
	isLoadingUsers: false,
	users: [],
	usersDictionary: {},
	loadUsers: async (params) => {},
});