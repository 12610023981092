import React, { useState, useContext } from 'react';
import CustomSelector, { CustomSelectorProps } from '../CustomSelector/CustomSelector';
import { useEnums } from '../../hooks/useEnums';
import { LabelsContext } from '../../context';

interface LocalesSelectorProps {
	className?: CustomSelectorProps["className"];
	disabled?: CustomSelectorProps["disabled"];
	multiple?: CustomSelectorProps["multiple"];
	handleChange: (field: string, value: any) => void;
	value: string | string[];
}

export const LocalesSelector: React.FunctionComponent<LocalesSelectorProps> = ({ className, disabled, value, handleChange, multiple }) => {
	const [selectedLocale, setSelectedLocale] = useState(value);
	const { labels } = useContext(LabelsContext);
	const { localesOptions } = useEnums(labels);

	const onChange = (field: string, value: string) => {
		setSelectedLocale(value as string);
		handleChange(field, value);
	};

	return (
		<CustomSelector
			id="Locales"
			name="locales"
			className={className}
			label={labels.language}
			value={selectedLocale}
			options={localesOptions}
			handleChange={onChange}
			disabled={disabled}
			multiple={multiple}
		/>
	);
}

export default LocalesSelector;