import React from 'react';
import { Chip } from '../Chip';
import { withTooltip } from '../../HOC';

interface SkillChipProps {
	hours: number;
	labels: {
		hours: string;
	};
	skill: {
		title: string;
	}
}

export const SkillChip: React.FunctionComponent<SkillChipProps> = ({ hours, labels, skill }) => {
	// TODO: Skill color based on category
	// TODO: Skill level in content
	// TODO: OnClick functionality
	if (hours) {
		return withTooltip(
			<Chip
				label={skill.title}
				className="margin--quarter-rem"
			/>,
			`${hours} ${labels.hours}`
		);
	}
	return (
		<Chip
			label={skill.title}
			className="margin--quarter-rem"
		/>
	);
}
