import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { HeadersComponent } from './Header';
import { CustomRow } from './CustomRow';
import { SpinnerText } from '../SpinnerText';
import { SortDirection, useTableSorting } from './useTableSorting';
import { useIsMobileViewport } from '../../hooks';
import { Column, SupportedRowType } from './CustomTable.types';

export interface SupportedObject {
	[key: string]: string | number | boolean | Date | SupportedObject
}

export interface Row {
	id: string;
}

export enum SelectionType {
	single = 'single',
	multiple = 'multiple',
}

export type Selector<T extends SupportedRowType = SupportedRowType> = {
	selectionType: SelectionType;
	selectAll: () => void;
	selectLines: (objects: T[]) => void;
	overwriteSelected: (objects: T[]) => void;
}

export type CustomTableProps<T extends SupportedRowType = SupportedRowType> = {
	isLoading: boolean;
	rows: T[];
	selectedLines: T[];
	disabled: boolean;
	columns: Column[];
	sortColumn: Column;
	sortDirection: SortDirection;
	selector: Selector<T>;
}

export const CustomTable = <T extends SupportedRowType = SupportedRowType>(props: CustomTableProps<T>) => {
	const { isLoading, rows, selectedLines, disabled, columns, sortColumn, sortDirection } = props;

	const headerChecked = !!rows.length && rows.length === selectedLines.length;
	const headerIntermediate = selectedLines.length > 0 && selectedLines.length < rows.length;

	const _localUseTableSortingHook = useTableSorting(sortColumn, sortDirection);
	const isAscending = _localUseTableSortingHook.isAscending;
	const setIsAscending = _localUseTableSortingHook.setIsAscending;
	const setSortColumn = _localUseTableSortingHook.setSortColumn;
	const sortColumnHook = _localUseTableSortingHook.sortColumn;
	const sortList = _localUseTableSortingHook.sortList;
	const sortedList = sortList(rows);

	const { isMobile } = useIsMobileViewport();

	return (
		<Table>
			<HeadersComponent
				{...props}
				sortColumn={sortColumnHook}
				setSortColumn={setSortColumn}
				isAscending={isAscending}
				setIsAscending={setIsAscending}
				disabled={disabled}
				isMobile={isMobile}
				columns={columns}
				headerChecked={headerChecked}
				headerIntermediate={headerIntermediate}
				selector={props.selector}
			/>
			<TableBody>
				{isLoading ? (
					<TableRow>
						<TableCell align="center" colSpan={999}>
							<div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: 100,
							}}>
								<SpinnerText />
							</div>
						</TableCell>
					</TableRow>
				) : (
					sortedList.map((row, index) =>
						<CustomRow<T>
							key={row.id}
							index={index}
							row={row as T}
							selectedLines={selectedLines}
							disabled={disabled}
							isMobile={isMobile}
							columns={columns}
							selector={props.selector}
						/>
					)
				)}
			</TableBody>
		</Table>
	);
};