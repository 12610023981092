import { TYPES_ENUM } from '../../../API';
import { ObjectFieldValidator } from '../../../validators';
import { Author } from './author';
import { buildGetModel } from '../../../models/buildGetModel';
import { RowType } from '../../../components';

export interface Book extends RowType {
	id: string;
	title: string,
	authors: Author[],
};

export interface BookDictionary {
	[key: Book["id"]]: Book;
};

export const defaultBook: Book = {
	id: '',
	title: '',
	authors: [],
};

export const getBook = buildGetModel<Book, BookDictionary>(TYPES_ENUM.BOOK, defaultBook);

export const validateBookField: ObjectFieldValidator<Book> = (fieldName, value) => {
	if (fieldName === 'title' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	if (fieldName === 'authors' && !value.length) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	return {
		isValid: true,
		messageId: '',
	}
}