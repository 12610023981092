import React from 'react';
import { TableCell, TableHead, TableRow, Checkbox, TableCellProps } from '@mui/material';
import { HeaderCellComponent } from './HeaderCellComponent';
import { ColumnTypesEnum } from './ColumnTypes';
import { Column, SupportedRowType } from './CustomTable.types';
import { Selector } from './CustomTable';

export interface HeaderDefinition<T> {
	align?: TableCellProps["align"]
	fieldName: keyof T
	headerText: string
	type: ColumnTypesEnum
	defaultValue?: string | number | boolean | Date | null
}

interface HeaderProps<T extends SupportedRowType = SupportedRowType> {
	columns: Column[];
	disabled: boolean;
	headerChecked: boolean;
	headerIntermediate: boolean;
	isAscending: boolean;
	isMobile: boolean;
	setIsAscending: (newValue: boolean) => void;
	setSortColumn: (sortColumn: Column) => void;
	sortColumn: Column;
	selector: Selector<T>;
}

export const HeadersComponent = <T extends SupportedRowType = SupportedRowType>(props: HeaderProps<T>) => {
	const { sortColumn, isAscending, setIsAscending, setSortColumn, headerIntermediate, headerChecked, selector, disabled, isMobile, columns } = props;
	const handleHeaderClick = (column: Column) => {
		if (sortColumn.cellName === column.cellName) {
			setIsAscending(!isAscending);
		} else {
			setSortColumn(column);
			setIsAscending(true);
		}
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={headerIntermediate}
						checked={headerChecked}
						onChange={selector.selectAll}
						disabled={disabled}
					/>
				</TableCell>
				{isMobile && <TableHeaderMobile columns={columns} />}
				{
					!isMobile && (columns || []).map((column, index) => {
						return (
							<HeaderCellComponent
								columnType={ColumnTypesEnum.string}
								key={index}
								handleHeaderClick={handleHeaderClick}
								isAscending={isAscending}
								sortColumn={sortColumn}
								column={column}
							/>
						);
					})
				}
			</TableRow>
		</TableHead>
	);
};

interface Props {
	columns: Column[];
}

const TableHeaderMobile: React.FunctionComponent<Props> = ({
	columns
}) => {
	const columnTitles: string = columns.map((column) => column.headerTitle).join(', ');
	return (
		<TableCell>
			{columnTitles}
		</TableCell>
	);
}