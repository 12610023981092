import { TYPES, TYPES_ENUM } from './TYPES';
import { patchItemFactory } from './patchItemFactory';

export const patchItem = <T>(type: TYPES_ENUM) => {
	switch (type) {
		case TYPES.ACCOUNTING_RECORD: return patchItemFactory<T>(type);
		case TYPES.ACCOUNT: return patchItemFactory<T>(type);
		case TYPES.ASSET: return patchItemFactory<T>(type);
		case TYPES.AUTHOR: return patchItemFactory<T>(type);
		case TYPES.DAY_STAT: return patchItemFactory<T>(type);
		case TYPES.DAY: return patchItemFactory<T>(type);
		case TYPES.BOOK: return patchItemFactory<T>(type);
		case TYPES.BOOK_READING: return patchItemFactory<T>(type);
		case TYPES.CONTACT: return patchItemFactory<T>(type);
		case TYPES.EXTERNAL_ENTITY: return patchItemFactory<T>(type);
		case TYPES.LABEL: return patchItemFactory<T>(type);
		case TYPES.MOVIE: return patchItemFactory<T>(type);
		case TYPES.MOVIESCREENING: return patchItemFactory<T>(type);
		case TYPES.NOTE: return patchItemFactory<T>(type);
		case TYPES.PHOTO: return patchItemFactory<T>(type);
		case TYPES.RECIPE: return patchItemFactory<T>(type);
		case TYPES.OBJECTIVE: return patchItemFactory<T>(type);
		case TYPES.OBJECTIVE_PLAN: return patchItemFactory<T>(type);
		case TYPES.OWNED_BOOK: return patchItemFactory<T>(type);
		case TYPES.TAG: return patchItemFactory<T>(type);
		case TYPES.INGREDIENT: return patchItemFactory<T>(type);
		case TYPES.TRANSACTION_BUNDLE: return patchItemFactory<T>(type);
		case TYPES.LOG_ENTRY: return patchItemFactory<T>(type);
		case TYPES.TIMELINE_DOT: return patchItemFactory<T>(type);
		case TYPES.TRANSACTION: return patchItemFactory<T>(type);
		case TYPES.WISHED_BOOK: return patchItemFactory<T>(type);
		default: {
			throw new Error('type not implemented');
		}
	};
};