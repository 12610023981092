import axios from 'axios';
import { getDefaultHeaders } from './getDefaultHeaders';
import { getResourceURL } from './getResourceURL';
import { TYPES_ENUM } from './TYPES';

export const deleteItemFactory = (type: TYPES_ENUM) => {
	return async (id: string) => {
		if (!id) throw new Error('No key passed for deleting!');
		try {
			await axios.delete(
				getResourceURL(type, id),
				{
					headers: getDefaultHeaders()
				}
			);
		} catch (error) {
			throw error;
		}
	};
};
