import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button } from '../Button';
import Textarea from '../Textarea/Textarea';

interface MarkdownEditorProps {
	// TODO: Change to TextArea props
	classes?: {
		input?: string;
	};
	name: string;
	value: string;
	onChange: (e: React.ChangeEvent) => void;
	label?: string;
	placeholder?: string;
	error: boolean;
	inputId: string;
	variant?: string;
	required?: boolean;
	autoFocus?: boolean;
	outlined?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	fullWidth?: boolean;
}

export const MarkdownEditor: React.FunctionComponent<MarkdownEditorProps> = (props) => {
	return (
		<div>
			<div
				style={{
					border: '1px solid rgba(0, 0, 0, 0.87)',
					borderRadius: '4px',
					minHeight: '56px',
					marginBottom: '4px',
					textAlign: 'left',
					padding: '5px'
				}}
			>
				<ReactMarkdown children={props.value} remarkPlugins={[remarkGfm]} />
			</div>
			<Button
				href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
				target="_blank"
				variant="contained"
				style={{ marginBottom: '4px', marginRight: '4px' }}
			>
				Open cheat sheet
			</Button>
			<Button
				href="https://css-tricks.com/snippets/html/glyphs/"
				target="_blank"
				variant="contained"
				style={{ marginBottom: '4px' }}
			>
				Entity list
			</Button>
			<Textarea
				autoFocus={props.autoFocus}
				error={props.error}
				name={props.name}
				className={props?.classes?.input}
				disabled={props.disabled}
				variant="outlined"
				required={props.required}
				fullWidth={props.fullWidth}
				id={props.inputId}
				label={props.label}
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
			/>
		</div>
	);
};

export default MarkdownEditor;