import React from 'react';
import { Button } from '../Button';
import { Icon, ICON_NAMES } from '..';

interface DeleteButtonProps {
	onClick: () => void;
	disabled?: boolean;
	labels: { delete: string }
}

export const DeleteButton: React.FunctionComponent<DeleteButtonProps> = ({ onClick, disabled, labels }) => {
	return (
		<Button
			variant="contained"
			color="primary"
			onClick={onClick}
			disabled={disabled}
		>
			<Icon iconName={ICON_NAMES.delete} />
			{labels.delete}
		</Button>
	);
};

export default DeleteButton;