import { TYPES_ENUM } from '../../../API';
import { DEFAULT_NEW_QUERY_ID } from '../../../constants';
import { Book, User, defaultBook, defaultUser } from '../../../models';
import { buildGetModel } from '../../../models/buildGetModel';
import { SuportedObject } from '../../../utils/filterListByFilters';
import { ObjectFieldValidator } from '../../../validators';

export interface WishedBook extends SuportedObject {
	id: string;
	details: string,
	dateIn: Date,
	book: Book,
	user: User,
};

export interface WishedBookDictionary {
	[key: WishedBook["id"]]: WishedBook;
};

export const defaultWishedBook: WishedBook = {
	id: '',
	details: '',
	dateIn: new Date(),
	book: { ...defaultBook },
	user: { ...defaultUser },
};

export const getWishedBook = buildGetModel<WishedBook, WishedBookDictionary>(TYPES_ENUM.OWNED_BOOK, defaultWishedBook);

export const mapWishedBookForApi = (queryId: string, WishedBook: WishedBook): any => {
	let payload: { [key: string]: any } = {
		details: WishedBook.details,
		dateIn: WishedBook.dateIn,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = WishedBook.id;
	}
	if (queryId === DEFAULT_NEW_QUERY_ID) {
		payload.bookId = WishedBook.bookId;
	}
	return payload;
};

export const validateWishedBookField: ObjectFieldValidator<WishedBook> = (fieldName, value) => {
	if (fieldName === 'bookId' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	if (fieldName === 'dateIn' && !value) {
		return {
			isValid: false,
			messageId: 'fieldIsMandatory',
		}
	}
	return {
		isValid: true,
		messageId: '',
	}
}