import React, { useContext } from 'react';
import { LabelsContext } from '../../context';
import { App } from '../../AppEnum';
import { getAppName } from './getAppName';
import { Typography } from '../Typography';

interface AppNameProps {
	app: App,
}

export const AppName: React.FunctionComponent<AppNameProps> = ({ app }) => {
	const { labels } = useContext(LabelsContext);
	const title = getAppName(app, labels);
	return (
		<Typography
			align="center"
			color="textPrimary"
			display="block"
			variant="h6"
		>
			{title}
		</Typography>
	);
};