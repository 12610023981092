import React, { FunctionComponent } from 'react';
import { TableCell } from '@mui/material';
import { ColumnTypesEnum } from './ColumnTypes';
import moment from 'moment';
import { BooleanCell } from './components/BooleanCell';
import { DateCell } from './DateCell';
import { CellCustomRenderer, Column, SupportedRowType } from './CustomTable.types';
import { getCellValue } from './custom-table-helpers';

interface CustomCellProps {
	rowIndex: number;
	columnIndex: number;
	column: Column;
	row: SupportedRowType;
}

export const CustomCell: React.FC<CustomCellProps> = ({
	rowIndex,
	columnIndex,
	column,
	row
}) => {
	const cellValue = getCellValue(row, column);
	return (
		<TableCell key={`row-${rowIndex}-column-${columnIndex}`}>
			{(column.renderType === ColumnTypesEnum.boolean) && <BooleanCell value={cellValue as boolean} />}
			{(column.renderType === ColumnTypesEnum.custom && column.customRenderer) && <CustomRendererCell value={cellValue as SupportedRowType} renderer={column.customRenderer} index={rowIndex} row={row} />}
			{(column.renderType === ColumnTypesEnum.date) && <DateCell value={cellValue as Date} />}
			{(column.renderType === ColumnTypesEnum.momentDayMonthYear) && <CustomMomentCell value={cellValue as string} />}
			{(column.renderType === ColumnTypesEnum.number) && <NumberCell value={cellValue as number} />}
			{(column.renderType === ColumnTypesEnum.string) && <StringCell value={cellValue as string} />}
		</TableCell>
	);
};

interface CustomRendererCellProps {
	value: SupportedRowType;
	renderer: CellCustomRenderer;
	index: number;
	row: any;
}

const CustomRendererCell: FunctionComponent<CustomRendererCellProps> = ({ value, renderer, index, row }) => {
	if (!renderer) return null;
	return renderer(value, index, row);
};

interface CustomMomentCellProps {
	value: string;
}

const CustomMomentCell: FunctionComponent<CustomMomentCellProps> = ({ value }) => {
	const date = moment(value, 'DD-MM-YYYY');
	if (!date.isValid()) return null;
	return <>{date.format('DD-MM-YYYY')}</>;
};

interface NumberCellProps {
	value: number;
}

const NumberCell: FunctionComponent<NumberCellProps> = ({ value }) => {
	return <>{value}</>;
};

interface StringCellProps {
	value: string;
}

const StringCell: FunctionComponent<StringCellProps> = ({ value }) => {
	return <>{value}</>;
};