import { App } from '../../AppEnum';
import { LabelsGraph } from '../../hooks';

export const getAppName = (app: App, labels: LabelsGraph): string => {
	const appLabels: { [key in App]: string } = {
		[App.none]: '',
		[App.admin]: labels.adminStuff,
		[App.books]: labels.books,
		[App.finance]: labels.finance,
		[App.movies]: labels.movies,
		[App.progress]: labels.progress,
		[App.recipes]: labels.recipes,
		[App.health]: labels.healthAppTitle,
		[App.minimalist]: labels.healthAppTitle, // TODO: Add label
	};
	return appLabels[app];
}