import React, { useState } from 'react';
import { Option } from '../CustomSelector';
import { CircularProgress, TextField } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { getInputChangeParams } from '../../utils';

export interface SearchBoxProps {
	data: Option[];
	isLoading: boolean;
	handleChange: (newString: string, newOption: Option) => void;
	defaultValue: Option | null;
	initialValue: Option | null;
	isOptionEqualToValue: AutocompleteProps<any, any, any, any>['isOptionEqualToValue'];
	label?: string;
	disabled?: boolean;
	required?: boolean;
}

export const SearchBox: React.FunctionComponent<SearchBoxProps> = (props) => {
	const { data, isLoading, handleChange, defaultValue, initialValue, isOptionEqualToValue, label, disabled, required } = props;
	const [value, setValue] = useState<Option | null>(initialValue);

	const localHandleOnChange: AutocompleteProps<any, boolean, any, any>['onChange'] = (e, newValue, reason) => {
		const [, inputValue] = getInputChangeParams<string, string>(e as React.ChangeEvent);
		setValue(newValue);
		handleChange(inputValue, newValue);
	};

	return (
		<Autocomplete
			disablePortal
			options={data}
			loading={isLoading}
			disabled={disabled || isLoading}
			onChange={localHandleOnChange}
			clearOnEscape
			defaultValue={defaultValue}
			value={value}
			isOptionEqualToValue={isOptionEqualToValue}
			renderInput={(params) => (
				<TextField
					{...{ ...params, ...{ props }, ...{ label } }}
					InputProps={{
						...params.InputProps,
						...{ required },
						endAdornment: (
							<>
								{isLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					disabled={isLoading}
				/>
			)}
		/>
	);
}
