import { createContext } from "react";

interface ErrorContextType {
	error: string;
	updateError: (error: Error) => void;
}

export const ErrorContext = createContext<ErrorContextType>({
	error: '',
	updateError: (error: Error) => {},
});