import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { getItems as fetchItems } from '../API';
import { TYPES_ENUM } from '../API/TYPES';
import { ErrorContext } from '../context';
import { User, UserDictionary } from '../models';
import { turnListIntoDictionary } from '../utils';

type ResponseType = [
	User[],
	UserDictionary,
	boolean,
	(params: {}) => Promise<void>,
];

export const useUsers = (): ResponseType => {
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [users, setUsers] = useState<User[]>([]);
	const { updateError } = useContext(ErrorContext);

	const loadUsers = useCallback(async (params) => {
		setUsers([]);
		setIsLoadingUsers(true);
		try {
			const result = await fetchItems<User>(TYPES_ENUM.USER)(params);
			setUsers(result);
		} catch (error) {
			updateError(error as Error);
		} finally {
			setIsLoadingUsers(false);
		}
	}, [updateError]);

	useEffect(() => {
		loadUsers({});
	}, [loadUsers]);

	const usersDictionary = useMemo(() => {
		return turnListIntoDictionary(users, 'id');
	}, [users]);

	return [users, usersDictionary, isLoadingUsers, loadUsers];
};