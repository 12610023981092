import { TYPES_ENUM } from './TYPES';
import { postItemFactory } from './postItemFactory';

export const postItem = <T>(type: TYPES_ENUM) => {
	switch (type) {
		case TYPES_ENUM.ACCOUNTING_RECORD: return postItemFactory<T>(type);
		case TYPES_ENUM.ACCOUNT: return postItemFactory<T>(type);
		case TYPES_ENUM.ASSET: return postItemFactory<T>(type);
		case TYPES_ENUM.AUTHOR: return postItemFactory<T>(type);
		case TYPES_ENUM.BOOK: return postItemFactory<T>(type);
		case TYPES_ENUM.BOOK_READING: return postItemFactory<T>(type);
		case TYPES_ENUM.DAY: return postItemFactory<T>(type);
		case TYPES_ENUM.EXTERNAL_ENTITY: return postItemFactory<T>(type);
		case TYPES_ENUM.LOG_ENTRY: return postItemFactory<T>(type);
		case TYPES_ENUM.LOGIN: return postItemFactory<T>(type);
		case TYPES_ENUM.LABEL: return postItemFactory<T>(type);
		case TYPES_ENUM.MOVIE: return postItemFactory<T>(type);
		case TYPES_ENUM.MOVIESCREENING: return postItemFactory<T>(type);
		case TYPES_ENUM.NOTE: return postItemFactory<T>(type);
		case TYPES_ENUM.DAY_STAT: return postItemFactory<T>(type);
		case TYPES_ENUM.PHOTO: return postItemFactory<T>(type);
		case TYPES_ENUM.PROJECT: return postItemFactory<T>(type);
		case TYPES_ENUM.RECIPE: return postItemFactory<T>(type);
		case TYPES_ENUM.OBJECTIVE: return postItemFactory<T>(type);
		case TYPES_ENUM.OWNED_BOOK: return postItemFactory<T>(type);
		case TYPES_ENUM.SKILL: return postItemFactory<T>(type);
		case TYPES_ENUM.STORY: return postItemFactory<T>(type);
		case TYPES_ENUM.TRANSACTION: return postItemFactory<T>(type);
		case TYPES_ENUM.USER: return postItemFactory<T>(type);
		case TYPES_ENUM.LABEL_GRAPH: return postItemFactory<T>(type);
		case TYPES_ENUM.MOVIE_GRAPH: return postItemFactory<T>(type);
		case TYPES_ENUM.TRANSACTION_GRAPH: return postItemFactory<T>(type);
		case TYPES_ENUM.TAG: return postItemFactory<T>(type);
		case TYPES_ENUM.INGREDIENT: return postItemFactory<T>(type);
		case TYPES_ENUM.TRANSACTION_BUNDLE: return postItemFactory<T>(type);
		case TYPES_ENUM.OBJECTIVE_STATS: return postItemFactory<T>(type);
		case TYPES_ENUM.OBJECTIVE_PLAN: return postItemFactory<T>(type);
		case TYPES_ENUM.TIMELINE_DOT: return postItemFactory<T>(type);
		case TYPES_ENUM.WISHED_BOOK: return postItemFactory<T>(type);
		default: {
			throw new Error('type not implemented');
		}
	}
}