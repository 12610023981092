import { createContext } from 'react';
import { buildTransactionTypeDictionary, buildTransactionTypes, CurrencyDictionary, TransactionTypeDictionary } from '../Apps/FinanceApp/models';

export interface Option {
	label: string;
	value: string | number;
}

interface APIEnumsContextProps {
	currenciesList: Option[];
	currenciesDictionary: CurrencyDictionary;
	transactionTypesList: Option[];
	transactionTypesDictionary: TransactionTypeDictionary;
}

export const APIEnumsContext = createContext<APIEnumsContextProps>({
	currenciesList: [],
	currenciesDictionary: {},
	transactionTypesList: buildTransactionTypes({ encashment: '', payment: '', internalTransfer: '' }),
	transactionTypesDictionary: buildTransactionTypeDictionary({ encashment: '', payment: '', internalTransfer: '' }),
});