import './MoviesApp.css';
import React, { Suspense, lazy, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
	Preloader,
	SuccessModal,
	ErrorModal,
	AdminDrawer
} from '../../components';
import { withRole } from '../../HOC';
import { buildRolesDictionary, Roles, turnListIntoDictionary } from '../../utils';
import { useUsers, useAPIList, useLabels } from '../../hooks';
import { UserContext } from '../../context/UserContext';
import { UsersContext, TagsContext, LabelsContext, LocaleContext } from '../../context';
import { TYPES_ENUM } from '../../API/TYPES';
import { Tag, mapTagsFromAPI } from '../../models';

const Movies = lazy(() => import('./pages/Movies/MoviesPage'));
const Movie = lazy(() => import('./pages/Movies/MoviePage'));
const MovieScreenings = lazy(() => import('./pages/MovieScreenings/MovieScreenings'));
const MovieScreeningForm = lazy(() => import('./pages/MovieScreenings/MovieScreeningForm'));
const NotFound = lazy(() => import('../../pages/Common/NotFound/NotFound'));

export const MoviesApp = () => {
	const { user } = React.useContext(UserContext);
	const { locale } = useContext(LocaleContext);
	const [users, usersDictionary, isLoadingUsers, loadUsers] = useUsers();
	const [
		isLoadingTags,
		isDeletingTag,
		tagsRaw, ,
		loadTags,
		selectTags,
		selectAllTags,
		deleteTag,
		overwriteSelected,
		selectedTags
	] = useAPIList<Tag>(TYPES_ENUM.TAG);
	const tags = mapTagsFromAPI(tagsRaw);
	const tagDictionary = turnListIntoDictionary(tags);

	const adminOnly = buildRolesDictionary([Roles.admin]);
	const { isLoadingLabels, labels } = useLabels(locale);

	if (isLoadingLabels || isLoadingUsers || isLoadingTags) return <Preloader />;
	return (
		<LabelsContext.Provider value={{ isLoadingLabels: isLoadingLabels, labels: labels }}>
			{/* @ts-ignore */}
			<UsersContext.Provider value={{ isLoadingUsers, users, loadUsers, usersDictionary }}>
				<TagsContext.Provider value={{
					deleteTag,
					isDeletingTag,
					isLoadingTags,
					tags,
					tagDictionary,
					loadTags,
					selectAllTags,
					selectedTags,
					selectTags,
					overwriteSelected,
				}}>
					<div className='admin-app'>
						<div className='drawer-wrapper'>
							<AdminDrawer />
						</div>
						<main className='page-wrapper'>
							<Suspense fallback={<Preloader isDbz />}>
								<Switch>
									<Route exact path="/" component={withRole(MovieScreenings, adminOnly, user)} />
									<Route exact path="/movieScreenings" component={withRole(MovieScreenings, adminOnly, user)} />
									<Route exact path="/movieScreenings/:id" component={withRole(MovieScreeningForm, adminOnly, user)} />
									<Route exact path="/movies" component={withRole(Movies, adminOnly, user)} />
									<Route exact path="/movies/:id" component={withRole(Movie, adminOnly, user)} />
									<Route component={NotFound} />
								</Switch>
							</Suspense>
						</main>
						<ErrorModal />
						<SuccessModal handleClose={() => { }} isOpen={false} message="" />
					</div>
				</TagsContext.Provider>
			</UsersContext.Provider>
		</LabelsContext.Provider>
	);
}

export default MoviesApp;