import axios from 'axios';
import { getDefaultHeaders } from './getDefaultHeaders';
import { getResourceURL } from './getResourceURL';
import { parseDefaultResult } from './parseDefaultResult';
import { TYPES_ENUM } from './TYPES';

export const patchItemFactory = <T>(type: TYPES_ENUM) => {
	return (id: string, object: T): Promise<T> => {
		return new Promise((resolve, reject) => {
			if (!id) throw new Error('No id passed for patching!');
			if (!object) throw new Error('No object passed for patching!');
			axios.patch(
				getResourceURL(type, id),
				object,
				{
					headers: getDefaultHeaders()
				}
			)
				.then(response => resolve(parseDefaultResult(response)))
				.catch(err => reject(err))
		});
	};
};