import { useCallback, useContext, useEffect, useState } from 'react';
import { getItems } from '../API/getItems';
import { TYPES_ENUM } from '../API/TYPES';
import { ErrorContext } from '../context';
import { remove } from 'lodash';
import { deleteItem } from '../API';

export type ObjectSelector<T> = (newSelected: T[]) => void;

type UseAPIListTuple<T> = [
	boolean,
	boolean,
	T[],
	string[],
	(params: any) => Promise<void>,
	ObjectSelector<T>,
	() => void,
	(id: string) => Promise<void>,
	(newSelected: T[]) => void,
	T[],
];

export type MappingFunction<T> = (rawValue: any) => T

export const useAPIList = <T>(
	type: TYPES_ENUM,
	//@ts-ignore
	listDictionaryKey?: keyof T = 'id',
	initialParams?: {},
	mappingFunction?: MappingFunction<T>,
	loadData = true,
): UseAPIListTuple<T> => {
	loadData = loadData === undefined ? true : loadData;
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [list, setList] = useState<T[]>([]);
	const [selected, setSelected] = useState<string[]>([]);
	const [selectedObjects, setSelectedObjects] = useState<T[]>([]);
	const { updateError } = useContext(ErrorContext);

	const loadList = useCallback(async (params) => {
		setIsLoading(true);
		setList([]);
		setSelected([]);
		setSelectedObjects([]);
		try {
			const result = await getItems<T>(type)(params);
			if (!Array.isArray(result)) {
				console.warn('What is this API ROUTE, mate?!')
				setList([]);
			} else {
				const mapped = mappingFunction ? result.map(rawValue => mappingFunction(rawValue)) : result;
				setList(mapped);
			}
		} catch (error) {
			updateError(error as Error);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, updateError]);

	useEffect(() => {
		if (loadData === true) loadList(initialParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadList, loadData]);

	const deleteFromAPI = useCallback(
		async (id: string) => {
			setIsDeleting(true);
			try {
				await deleteItem(type)(id);
			} catch (error) {
				updateError(error as Error);
			} finally {
				setIsDeleting(false);
			}
		}, [type, updateError]);

	const selectObjects = useCallback(
		(newSelectedAccounts: T[]) => {
			if (!newSelectedAccounts.length) {
				setSelected([]);
				setSelectedObjects([]);
				return;
			}
			for (let i = 0; i < newSelectedAccounts.length; i++) {
				const newSelectedAccount = newSelectedAccounts[i];
				let found = selected.find(sl => newSelectedAccount[listDictionaryKey] === sl);
				if (found) {
					remove(selected, sl => sl === newSelectedAccount[listDictionaryKey]);
					remove(selectedObjects, sl => sl[listDictionaryKey] === found);
				} else {
					selected.push(newSelectedAccount[listDictionaryKey] as string);
					selectedObjects.push(newSelectedAccount);
				}
			}
			setSelected([...selected]);
			setSelectedObjects([...selectedObjects]);
		}, [selected, selectedObjects, listDictionaryKey]);

	const selectAll = useCallback(
		() => {
			if (selected.length && selected.length === list.length) {
				setSelected([]);
				setSelectedObjects([]);
				return;
			}
			setSelected(list.map((item) => item[listDictionaryKey] as string));
			setSelectedObjects(list);
			return;
		},
		[list, selected, listDictionaryKey]
	);

	const overwriteSelected = useCallback(
		(newSelected: T[]) => {
			if (selected[0] && selected[0] === newSelected[0]) {
				setSelected([]);
				setSelectedObjects([]);
				return;
			}
			setSelected(newSelected.map(item => item[listDictionaryKey]) as string[]);
			setSelectedObjects(newSelected);
		},
		[listDictionaryKey, selected]
	);

	return [
		isLoading,
		isDeleting,
		list,
		selected,
		loadList,
		selectObjects,
		selectAll,
		deleteFromAPI,
		overwriteSelected,
		selectedObjects
	];
}