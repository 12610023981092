import { SuportedObject } from '../../../utils/filterListByFilters';
import { defaultTag, Tag, TagDictionary } from '../../../models/tag';

export interface Ingredient extends SuportedObject {
	id: string;
	name: string;
	details: string;
	tags: Tag['id'][];
	displayedTags: string;
	nutritionalScores: {}[],
};

export interface IngredientDictionary {
	[key: Ingredient['id']]: Ingredient
};

export const defaultIngredient: Ingredient = {
	id: '',
	name: '',
	details: '',
	nutritionalScores: [],
	tags: [],
	displayedTags: '',
};

export const getIngredient = (source: any, ingredientDictionary: IngredientDictionary): Ingredient => {
		//@ts-ignore
		const found = ingredientDictionary[source];
		if (!found) {
			console.warn(`No ingredient found for "${source}"`, source, ingredientDictionary);
		}
		return found || defaultIngredient;
}

export const mapIngredientForApi = (_queryId: string, ingredient: Ingredient): any => {
	let payload: { [key: string]: any } = {
		id: ingredient.id,
		name: ingredient.name,
		details: ingredient.details,
		nutritionalScores: ingredient.nutritionalScores,
		tags: ingredient.tags,
	};
	return payload;
};

export const mapIngredientFromAPI = (
	source: any,
	tagDictionary: TagDictionary,
): Ingredient => {
	const displayedTags = (source.tags || []).reduce((prev: string, tagId: string) => {
		const foundTag = tagDictionary[tagId] || { ...defaultTag };
		return prev += foundTag.text + ' ';
	}, '') as string;

	return {
		id: source.id,
		details: source.details,
		displayedTags: displayedTags,
		name: source.name,
		nutritionalScores: source.nutritionalScores,
		tags: source.tags,
	}
};

export const mapIngredientsFromAPI = (
	sources: any[],
	tagDictionary: TagDictionary,
): Ingredient[] => {
	return sources.map((source) => mapIngredientFromAPI(source, tagDictionary));
};
