import { cloneDeep } from 'lodash';
import { DEFAULT_NEW_QUERY_ID } from '../constants';
import { SuportedObject } from '../utils/filterListByFilters';

/**
 * Returns default UI object
*/
export const getDefault = () => {
	return {
		id: '',
		text: '',
	};
};

/**
 * turns an object from the API intro an object for the UI
*/
export const formatFromAPI = (o: any) => {
	const result = cloneDeep(o);
	return result;
};

export interface Tag extends SuportedObject {
	id: string;
	text: string;
};


export interface TagDictionary {
	[key: Tag['id']]: Tag
};

export const defaultTag: Tag = {
	id: '',
	text: '',
};

export const mapTagForApi = (queryId: string, tag: Tag): any => {
	let payload: { [key: string]: any } = {
		text: tag.text,
	};
	if (queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = tag.id
	}
	return payload;
};

export const mapTagFromAPI = (source: any): Tag => {
	return {
		id: source.id,
		text: source.text,
	} as Tag;
};

export const mapTagsFromAPI = (sources: any[]): Tag[] => {
	return sources.map((source) => mapTagFromAPI(source));
};

interface ModelWithTags {
	tags: Tag['id'][];
};

export const filterByTags = <T extends ModelWithTags>(list: T[], tags: Tag['id'][]) => {
	if (tags.length) {
		return list.filter((object) => {

			let isValid = false;
			if (object.tags && Array.isArray(object.tags)) {
				isValid = objectHasAllTags(object, tags);
			} else {
				console.warn(`Object has invalid tags`, object);
			}
			return isValid;
		});
	}
	return list;
};

interface ObjectWithTags {
	tags: Tag['id'][];
}

export const objectHasAllTags = (object: ObjectWithTags, tags: Tag['id'][]): boolean => {
	const foundTags: Tag['id'][] = [];
	for (let i = 0; i < tags.length; i++) {
		const filterTag = tags[i];
		const found = object.tags.find(tag => tag === filterTag);
		if (found) foundTags.push(found);
	}
	return foundTags.length === tags.length;
};