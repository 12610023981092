//@ts-check
import React from 'react';
// import registerServiceWorker from './registerServiceWorker'
import Main from './js/Main';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Main />
	</BrowserRouter>
);
