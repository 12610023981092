import React from 'react'
import { TextField, TextFieldProps, FormControlLabel, Checkbox } from '@mui/material';
import { INPUT_VARIANTS } from './INPUT_VARIANTS';
import { INPUT_MARGINS } from './INPUT_MARGINS';
import { FormContext, IFormValidator } from '../../validators';

export type ValidationCallback = (name: string, value: any) => boolean;

export interface CustomTextfieldProps {
	type?: 'date' | 'checkbox' | 'text' | 'number' | 'password' | 'email';
	autoFocus?: boolean;
	className?: string;
	disabled?: boolean;
	error?: boolean;
	id: string;
	label?: string;
	name: string;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	required?: boolean;
	placeholder?: string;
	validationCallback?: ValidationCallback;
	value: string | number | boolean;
	checked?: boolean;
	color?: 'primary' | 'secondary' | 'default';
	maxRows?: number;
	autoComplete?: string;
	variant?: INPUT_VARIANTS
	shrink?: boolean;
	max?: number;
	min?: number;
	style?: TextFieldProps["style"];
	margin?: INPUT_MARGINS;
	formContext?: FormContext<any>,
	onChangeNew?: (field: string, value: boolean) => void;
	formValidator?: IFormValidator,
}

export const CustomTextfield: React.FunctionComponent<CustomTextfieldProps> = (props) => {
	// TODO: Check and remove type == date
	// TODO: Check and remove type == checkbox
	// TODO: Check and remove type == text
	if (props.type === 'date') {
		return (
			<TextField
				autoFocus={props.autoFocus}
				className={props.className}
				disabled={props.disabled}
				required={props.required}
				id={props.id}
				name={props.name}
				label={props.label}
				type={props.type}
				defaultValue={props.value}
				InputLabelProps={{
					shrink: true,
				}}
				onChange={props.onChange}
				placeholder={props.placeholder}
				style={props.style}
			/>
		);
	} else if (props.type === 'checkbox') {
		return (
			<FormControlLabel
				control={
					<Checkbox
						id={props.id}
						checked={props.checked}
						onChange={props.onChange}
						value={props.value === null ? "indeterminate" : props.value}
						indeterminate={props.value === null}
						color={props.color}
						name={props.name}
						style={props.style}
					/>
				}
				label={props.label}
			/>
		);
	} else {
		const evaluatedError = evaluateError(props);

		return (
			<TextField
				type={props.type || 'text'}
				autoFocus={props.autoFocus}
				maxRows={props.maxRows}
				error={evaluatedError}
				autoComplete={props.autoComplete}
				name={props.name}
				className={props.className}
				disabled={props.disabled}
				variant={props.variant || 'outlined'}
				required={props.required}
				fullWidth
				id={props.id}
				label={props.label}
				value={props.value}
				onChange={props.onChange}
				InputLabelProps={{
					shrink: props.shrink,
				}}
				style={props.style}
			/>
		);
	}

}
export default CustomTextfield;

const evaluateError = (props: CustomTextfieldProps): boolean => {
	const { formValidator, name, value, error, formContext, validationCallback } = props;
	if (formValidator) {
		return formValidator.fieldHasErrors(name, { [name]: value });
	}

	return (error !== undefined) ? error : (formContext ? !formContext.validateField(name, value).isValid : (validationCallback ? validationCallback(name, value) : false));
}
