import { AxiosRequestHeaders } from 'axios';
import { getToken } from './getToken';
import { getLocale } from './getLocale';
import { LOCAL_STORAGE_KEYS } from '../utils';

export const getDefaultHeaders = () => {
	const result: AxiosRequestHeaders = {
		'Content-type': 'application/json'
	};
	const token = getToken();
	if (token) {
		result[LOCAL_STORAGE_KEYS.token] = token;
	}
	const locale = getLocale();
	if (locale) {
		result[LOCAL_STORAGE_KEYS.locale] = locale;
	}
	return result;
};
