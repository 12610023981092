import { FunctionComponent } from 'react';
import { Icon, ICON_NAMES } from '../../Icon';

interface BooleanCellProps {
	value: boolean;
}

export const BooleanCell: FunctionComponent<BooleanCellProps> = ({ value }) => {
	if (value) return <Icon iconName={ICON_NAMES.Check} />;
	return null;
}