import { TYPES, TYPES_ENUM } from './TYPES';
import { RESOURCE_NAMES, RESOURCE_NAMES_ENUM } from './RESOURCE_NAMES';

/**
 * Intoarce numele resursei din API pt un tip de date.
*/
export const getResourceName = (type: TYPES_ENUM) => {
	if (!TYPES[type]) throw new Error('Invalid type passed!');
	const resourceName = RESOURCE_NAMES[type] as RESOURCE_NAMES_ENUM;
	if (!resourceName) throw new Error('No resource name found!');
	return resourceName;
};