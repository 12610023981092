import React, { useContext } from 'react';
import CustomSelector from '../CustomSelector/CustomSelector';
import { mapListToSelectorOptions } from '../../reducers/selectors';
import { TagsContext } from '../../context';

interface TagsSelectorProps {
	className?: string;
	disabled?: boolean;
	value: string[];
	label?: string;
	/**
	 * @deprecated
	 */
	handleChange: (newValue: string | string[]) => void;
	handleChangeNew?: (field: string, value: any) => void;
}

export const TagsSelector: React.FunctionComponent<TagsSelectorProps> = ({
	className,
	disabled,
	value,
	label,
	handleChange,
	handleChangeNew,
}) => {
	const { tags } = useContext(TagsContext);
	const tagSelectorOptions = mapListToSelectorOptions(tags, 'id', 'text');

	return (
		<CustomSelector
			id="Tags"
			name="tags"
			className={className}
			label={label}
			value={value}
			options={tagSelectorOptions}
			handleChange={handleChangeNew ? handleChangeNew : handleChange}
			disabled={disabled}
			multiple
		/>
	);
}

export default TagsSelector;