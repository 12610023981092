export enum TYPES_ENUM {
	ACCOUNTING_RECORD = 'ACCOUNTING_RECORD',
	ACCOUNT = 'ACCOUNT',
	ASSET = 'ASSET',
	AUTHOR = 'AUTHOR',
	BOOK = 'BOOK',
	ASSETHISTORY = 'ASSETHISTORY',
	ARTICLE = 'ARTICLE',
	CONTACT = 'CONTACT',
	CURRENCY = 'CURRENCY',
	DAY = 'DAY',
	EXCHANGE_RATE = 'EXCHANGE_RATE',
	EXTERNAL_ENTITY = 'EXTERNAL_ENTITY',
	INGREDIENT = 'INGREDIENT',
	LABEL_GRAPH = 'LABEL_GRAPH',
	LABEL = 'LABEL',
	LOG_ENTRY = 'LOG_ENTRY',
	LOGIN = 'LOGIN',
	MOVIE = 'MOVIE',
	MOVIESCREENING = 'MOVIESCREENING',
	OBJECTIVE = 'OBJECTIVE',
	PHOTO = 'PHOTO',
	PROJECT = 'PROJECT',
	SKILL = 'SKILL',
	STORY = 'STORY',
	TEXT = 'TEXT',
	TOKEN = 'TOKEN',
	TRANSACTION = 'TRANSACTION',
	USER = 'USER',
	NOTE = 'NOTE',
	LOCALE = 'LOCALE',
	MOVIE_GRAPH = 'MOVIE_GRAPH',
	TRANSACTION_GRAPH = 'TRANSACTION_GRAPH',
	ACTIVITY_SUGGESTION = 'ACTIVITY_SUGGESTION',
	TAG = 'TAG',
	RECIPE = 'RECIPE',
	TRANSACTION_BUNDLE = 'TRANSACTION_BUNDLE',
	DAY_STAT = 'DAY_STAT',
	OBJECTIVE_STATS = 'OBJECTIVE_STATS',
	OBJECTIVE_PLAN = 'OBJECTIVE_PLAN',
	TIMELINE_DOT = 'TIMELINE_DOT',
	OWNED_BOOK = 'OWNED_BOOK',
	BOOK_READING = 'BOOK_READING',
	WISHED_BOOK = 'WISHED_BOOK',
}

export const TYPES: { [key: string]: string } = Object.freeze({ ...TYPES_ENUM });