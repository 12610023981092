import React from 'react';
import { useTheme } from '@mui/material';
import { Story } from '../../models';
import './timeline-entry.css';

const BulletBubbleHeight = 2.5;

interface TimelineEntryProps {
	entry: Story;
	renderTitle: (entry: Story) => JSX.Element;
	renderIcon: (entry: Story) => JSX.Element;
	renderContent: (entry: Story) => JSX.Element;
	isFirst: boolean;
	isLast: boolean;
}

export const TimelineEntry: React.FunctionComponent<TimelineEntryProps> = ({
	entry,
	renderTitle,
	renderIcon,
	renderContent,
	isFirst,
	isLast,
}) => {
	const theme = useTheme();
	const topBorderStyles: React.CSSProperties = { height: `${BulletBubbleHeight}rem` };
	if (!isFirst) topBorderStyles.borderRight = '4px solid grey';

	return (
		<>
			<div className="left-item-wrapper">
				<TimelineLeftElement>
					<div className="title-element">
						{renderTitle(entry)}
					</div>
				</TimelineLeftElement>
				<TimelineLeftElement bordered isFirst={isFirst} isLast={isLast}>
					<span className="shadow">
						<span
							className="icon-wrapper"
							style={{
								backgroundColor: theme.palette.primary.contrastText,
								borderColor: theme.palette.primary.main,
								color: theme.palette.primary.main,
							}}
						>
							{renderIcon(entry)}
						</span>
					</span>
				</TimelineLeftElement>
			</div>
			<div className="right-item-wrapper">
				{renderContent(entry)}
			</div>
		</>
	);
};

interface TimelineLeftElementProps {
	bordered?: boolean;
	isFirst?: boolean;
	isLast?: boolean;
}

const TimelineLeftElement: React.FunctionComponent<TimelineLeftElementProps> = ({
	children,
	bordered,
	isFirst,
	isLast,
}) => {
	let topElementClassName = 'top-element';
	let lowerElementClassName = 'lower-element';
	let topBorderStyles: React.CSSProperties = { height: `${BulletBubbleHeight / 2}rem` };
	let lowerElementStyles: React.CSSProperties = { height: `calc(100% - ${BulletBubbleHeight / 2}rem)` };

	if (bordered) {
		if (!isFirst) {
			topElementClassName += ' bordered';
			topBorderStyles.borderRight = '4px solid grey';
		}
		if (!isLast) {
			lowerElementClassName += ' bordered';
			lowerElementStyles.borderRight = '4px solid grey';
		}
	}

	return (
		<div style={{ alignSelf: 'stretch', display: 'flex', flexDirection: 'column' }}>
			<div className={topElementClassName} style={topBorderStyles}>
				{children}
			</div>
			<div className={lowerElementClassName} style={lowerElementStyles} />
		</div>
	);
};

export default TimelineEntry;
