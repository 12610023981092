/**
 * Splits a list in 2 lists using a predicate
 * 
*/

type ResponseTuple<T> = [
	T[],
	T[],
];

type Responsefunction<T> = (list: T[]) => ResponseTuple<T>;

export const splitByPredicate = <T>(predicate: (value: T) => boolean): Responsefunction<T> => {
	return (list: T[]) => {
		const isTrue: T[] = [];
		const isFalse: T[] = [];
		for (let i = 0; i < list.length; i++) {
			const objective = list[i];
			if (predicate(objective)) {
				isTrue.push(objective);
			} else {
				isFalse.push(objective);
			}
		}
		return [isTrue, isFalse];
	};
};