import { SuportedObject } from "../../../utils/filterListByFilters";
import { defaultUser, User } from "../../../models";
import { Account, defaultAccount } from "./account";
import { DEFAULT_NEW_QUERY_ID } from "../../../constants";
import { buildGetModel } from "../../../models/buildGetModel";
import { TYPES_ENUM } from "../../../API";
import { ExternalEntityCategory } from "./external-entity-category";

export interface ExternalEntity extends SuportedObject {
	accountId: Account['id'],
	account: Account,
	category: ExternalEntityCategory,
	createdDate: Date,
	description: string,
	id: string,
	ownerId: User['id'];
	owner: User;
	title: string;
};

export interface ExternalEntityDictionary {
	[key: ExternalEntity['id']]: ExternalEntity
};

export const defaultExternalEntity: ExternalEntity = {
	accountId: '',
	account: { ...defaultAccount },
	category: ExternalEntityCategory.MISC,
	createdDate: new Date(),
	description: '',
	id: '',
	ownerId: '',
	owner: { ...defaultUser },
	title: '',
};

export const getExternalEntity = buildGetModel<ExternalEntity, ExternalEntityDictionary>(TYPES_ENUM.EXTERNAL_ENTITY, defaultExternalEntity);

export const mapExternalEntityForApi = (_queryId: string, externalEntity: ExternalEntity): any => {
	let payload: { [key: string]: any } = {
		accountId: externalEntity.accountId,
		category: externalEntity.category,
		description: externalEntity.description,
		ownerId: externalEntity.ownerId,
		title: externalEntity.title,
	};
	if (_queryId !== DEFAULT_NEW_QUERY_ID) {
		payload.id = externalEntity.id;
	}
	return payload;
};

export const mapExternalEntityFromAPI = (
	source: any,
): ExternalEntity => {
	return {
		id: source.id,
		account: source.account,
		accountId: source.accountId,
		category: source.category,
		createdDate: new Date(source.createdDate),
		description: source.description,
		owner: source.owner,
		ownerId: source.ownerId,
		title: source.title,
	};
};

export const mapExternalEntitiesFromAPI = (
	sources: any[],
): ExternalEntity[] => {
	return sources.map((source) => mapExternalEntityFromAPI(source));
};