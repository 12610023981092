import React from 'react';

export enum States {
	neutral = 'neutral',
	isWaiting = 'isWaiting',
	success = 'success',
	error = 'error',
}

export const StatesValues = Object.values(States);

interface APIStateMachineProps {
	status: States;
	isWaiting: JSX.Element;
	success: JSX.Element;
	error: JSX.Element;
	neutral: JSX.Element;
}

export const APIStateMachine: React.FunctionComponent<APIStateMachineProps> = (props) => {
	switch (props.status) {
		case States.isWaiting: return props[States.isWaiting];
		case States.success: return props[States.success];
		case States.error: return props[States.error];
		default: return props[States.neutral];
	}
}

export default APIStateMachine;