import './FormPage.css';
import React, { lazy } from 'react';
import { Paper } from '../Paper';
import { PageTitle, SpinnerText, KeyboardSniffer, SUPPORTED_EVENTS, SaveButton } from '..';
import { withGrowAnimation, withFormInputWrapper, withSuspense } from '../../HOC';
import { ExitButton } from '../ExitButton';
import { DEFAULT_ELEVATION } from '../../constants';

const Grid = lazy(() => import('../../components/Grid/Grid'));

interface FormPageProps {
	handleSave: () => void;
	isLoading: boolean;
	getPageTitle: () => string;
	/**
	 * @deprecated
	 * Include in children
	 */
	TabPanel?: JSX.Element;
	/**
	 * @deprecated
	 * Include in children
	 */
	renderBottomComponent?: (() => JSX.Element) | JSX.Element;
	actions: {
		save?: () => Promise<void>;
		explicitDisableSave?: boolean;
		exit?: () => void;
	};
	className?: string;
}

export const FormPage: React.FunctionComponent<FormPageProps> = (props) => {
	const { handleSave, isLoading, TabPanel, renderBottomComponent, children, className } = props;

	return withSuspense(withGrowAnimation(
		<div className="form-page-wrapper">
			{handleSave && <KeyboardSniffer handlers={{ [SUPPORTED_EVENTS.save]: handleSave }} />}
			<Paper className={'padding--half-rem ' + className} elevation={DEFAULT_ELEVATION}>
				<div className='form-page-container display-flex--column align-items--center width--hundred-percent'>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{renderTitle(props)}
					</Grid>
					{isLoading && (
						<div className='width--hundred-percent display-flex justify-content--center'>
							<SpinnerText />
						</div>
					)}
					<div className='display-flex--column gap--half-rem width--hundred-percent'>
						{children}
					</div>
					{TabPanel && withFormInputWrapper(TabPanel)}
					<div className='align-self--stretch'>
						{typeof renderBottomComponent == 'function' ? renderBottomComponent() : renderBottomComponent}
					</div>
					{!isLoading && renderButtons(props)}
				</div>
			</Paper>
		</div>
	));
}

export default FormPage;

const renderTitle = ({ getPageTitle }: FormPageProps) => {
	return <PageTitle text={getPageTitle()} />;
};

const renderButtons = ({ actions, isLoading }: FormPageProps) => {
	const isSaveDisabled = actions.explicitDisableSave ? actions.explicitDisableSave : isLoading;
	return (
		<div className="form-page-button-row">
			{actions.save && (
				<SaveButton onClick={actions.save} disabled={isSaveDisabled} />
			)}
			{actions.exit && (
				<ExitButton onClick={actions.exit} />
			)}
		</div>
	);
};