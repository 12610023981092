import axios from 'axios';
import { getDefaultHeaders } from './getDefaultHeaders';
import { getResourceURL } from './getResourceURL';
import { parseDefaultResult } from './parseDefaultResult';
import { TYPES_ENUM } from './TYPES';

export type GetItemFactoryReturnType<T> = (id: string, params?: { [key: string]: string }) => Promise<T>;

export const getItemFactory = <T>(type: TYPES_ENUM): GetItemFactoryReturnType<T> => {
	return async (id: string, params?: { [key: string]: string }) => {
		try {
			const response = await axios.get(
				getResourceURL(type, id, params),
				{
					headers: getDefaultHeaders()
				}
			);
			return parseDefaultResult(response);
		} catch (error: any) {
			throw error
		}
	};
};