import React from 'react';
import { Snackbar } from '@mui/material';
import { IconButton } from '../IconButton';
import { Icon, ICON_NAMES } from '../Icon';

interface SuccessModalProps {
	message: string;
	isOpen: boolean;
	handleClose: () => void;
};

export const SuccessModal: React.FunctionComponent<SuccessModalProps> = ({ message, isOpen, handleClose }) => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isOpen}
			autoHideDuration={3000}
			onClose={handleClose}
			message={<span id="message-id">{message}</span>}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleClose}
				>
					<Icon iconName={ICON_NAMES.cancel} />
				</IconButton>,
			]}
		/>
	);
}
