import React from 'react';
import { TableCell } from '@mui/material';
import { SortArrowComponent } from './SortArrowComponent';
import { ColumnTypesEnum } from './ColumnTypes';
import { Column } from './CustomTable.types';

interface HeaderCellComponentProps {
	column: Column
	handleHeaderClick: (column: Column) => void
	sortColumn: Column
	isAscending: boolean
	columnType: ColumnTypesEnum
}

export const HeaderCellComponent: React.FC<HeaderCellComponentProps> = ({
	column,
	handleHeaderClick,
	sortColumn,
	isAscending
}) => {
	const hasSorting = column.renderType !== ColumnTypesEnum.custom || (!!column.customSorter);
	return (
		<TableCell
			onClick={() => hasSorting ? handleHeaderClick(column) : null}
			style={hasSorting ? { cursor: 'pointer' } : {}}
		>
			{hasSorting && <SortArrowComponent columnName={column.cellName} sortColumn={sortColumn.cellName} isAscending={isAscending} />}
			{column.headerTitle}
		</TableCell>
	);
};