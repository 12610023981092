export enum ProjectStatusEnum {
	DEP = 'DEP',
	DN = 'DN',
	DEV = 'DEV',
	REQ = 'REQ',
	RES = 'RES',
};

export interface ProjectStatus {
	label: string;
	value: ProjectStatusEnum;
}

export interface ProjectStatusDictionary {
	[key: string]: ProjectStatus
};

export const defaultProjectStatus: ProjectStatus = {
	label: '',
	value: ProjectStatusEnum.REQ,
};

export const projectStatusDictionary: ProjectStatusDictionary = {

}