import { SuportedObject } from '../../../utils/filterListByFilters';
import { ObjectFieldValidator } from '../../../validators';
import { Book, defaultBook } from './book';
import { User, defaultUser } from '../../../models';

export interface BookReading extends SuportedObject {
	id: string;
	details: string,
	dateStart: Date | null,
	dateEnd: Date | null,
	book: Book,
	wasFinished: boolean,
	user: User,
};

export interface BookReadingDictionary {
	[key: BookReading["id"]]: BookReading;
};

export const defaultBookReading: BookReading = {
	id: '',
	details: '',
	dateStart: null,
	dateEnd: null,
	book: { ...defaultBook },
	wasFinished: false,
	user: { ...defaultUser }
};

export const validateBookReadingField: ObjectFieldValidator<BookReading> = (_fieldName, _value) => {
	return {
		isValid: true,
		messageId: '',
	}
}