import moment from 'moment';
import { useMemo, useState } from 'react';

export const intervalFieldNames = Object.freeze({
	startDate: 'startDate',
	endDate: 'endDate',
});

export type UseDateIntervalResponseTuple = [
	string,
	string,
	(fieldName: 'startDate' | 'endDate', value: string) => void,
	(startDate: string, endDate: string) => void,
];

export const useDateInterval = (initialStartDate?: string, initialEndDate?: string): UseDateIntervalResponseTuple => {
	initialStartDate = useMemo(() => initialStartDate || moment().startOf('M').format('YYYY-MM-DD'), [initialStartDate]);
	initialEndDate = useMemo(() => initialEndDate || moment().endOf('M').format('YYYY-MM-DD'), [initialEndDate]);
	const [interval, setInterval] = useState<{ startDate: string, endDate: string }>({ startDate: initialStartDate, endDate: initialEndDate });

	const updateInterval = (fieldName: 'startDate' | 'endDate', value: string) => {
		if (fieldName === intervalFieldNames.startDate) setInterval({ startDate: value, endDate: interval.endDate });
		if (fieldName === intervalFieldNames.endDate) setInterval({ startDate: interval.startDate, endDate: value });
	};

	const updateFullInterval = (_startDate: string, _endDate: string) => {
		setInterval({ startDate: _startDate, endDate: _endDate })
	};

	return [
		interval.startDate,
		interval.endDate,
		updateInterval,
		updateFullInterval
	];
};