import './custom-row-mobile.css';
import { Paper } from '../Paper';
import { Column, SupportedRowType } from './CustomTable.types';
import { getCellValue, getIsSelected } from './custom-table-helpers';

interface CustomRowMobileProps {
	selectedLines: SupportedRowType[];
	row: SupportedRowType;
	index: number;
	onLineClick: (() => void) | undefined;
	columns: Column[];
}

export const CustomRowMobile = (props: CustomRowMobileProps) => {
	const { selectedLines, row, index, onLineClick, columns } = props;
	let isItemSelected = getIsSelected(row, selectedLines || []);

	return (
		<tr onClick={onLineClick}>
			<td colSpan={columns.length + 1}>
				<Paper className={`mobile-row-paper${isItemSelected ? ' mobile-row-paper-selected' : ''}`}>
					<div className='mobile-row-grid-parent'>
						{columns.map(column => {
							const cellName = column.cellName as keyof SupportedRowType;
							if (row[cellName]) {
								const cellValue = getCellValue(row, column);
								return (
									<div className='mobile-row-grid-item' key={column.cellName}>
										<div>{column.headerTitle + ':'}</div>
										<div>{column.customRenderer ? column.customRenderer(cellValue, index, row) : (cellValue + '')}</div>
									</div>
								);
							}
							return null;
						})}
					</div>
				</Paper>
			</td>
		</tr>
	);
};