import axios from 'axios';
import { getDefaultHeaders } from './getDefaultHeaders';
import { getResourceURL } from './getResourceURL';
import { parseDefaultListResult } from './parseDefaultListResult';
import { TYPES_ENUM } from './TYPES';

/**
 * Builds the getAll function
*/
export const getAllFactory = <T>(type: TYPES_ENUM) => {
	return async (params: { [key: string]: string }) => {
		try {
			const response = await axios.get(
				getResourceURL(type, '', params),
				{
					headers: getDefaultHeaders()
				}
			);
			const val = parseDefaultListResult<T>(response);
			return val;
		} catch (error) {
			throw error;
		}
	};
};